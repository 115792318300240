namespace HomePage {
    declare var CountUp: any, numTransplants: number, numDrives: number;

    $(function () {
        let width = window.innerWidth;
        let $savingC = $('#saving-lives>.c'), $savingH2 = $('>h2', $savingC), $savingP = $('>p', $savingC), $savingBtn = $('>button', $savingC);
        $savingH2.css('transform', 'translate3D(-' + width + 'px,0,0)');
        $savingP.css('transform', 'translate3D(' + width + 'px,0,0)');
        $savingBtn.css('transform', 'translate3D(-' + width + 'px,0,0)');
        $savingC.show();
        setTimeout(function () { $savingH2.css({ 'transform': 'translate3D(0,0,0)' }); }, 100);
        setTimeout(function () { $savingP.css({ 'transform': 'translate3D(0,0,0)' }); }, 1100);
        setTimeout(function () { $savingBtn.css({ 'transform': 'translate3D(0,0,0)' }); }, 2100);

        let $numTransplants = $('#num-transplants'), $numDrives = $('#num-drives');

        let numTransplantsCountUp = new CountUp('num-transplants', 0, numTransplants, 0, 3, {}),
            numDrivesCountUp = new CountUp('num-drives', 0, numDrives, 0, 3, {});

        $scrollEl.scroll(onScroll);
        $window.resize(onScroll);
        onScroll();
        function onScroll() {
            if ($numTransplants.offset()!.top < $window.height()!)
                numTransplantsCountUp.start();
            if ($numDrives.offset()!.top < $window.height()!)
                numDrivesCountUp.start();
        }

        (<any>window).memberLoginFromQueryString();

    });
}